@font-face {
  font-family: "Poppins";
  font-weight: 400;
  src: local("Poppins"),
    url(./fonts/poppins/Poppins-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 500;
  src: local("Poppins"),
    url(./fonts/poppins/Poppins-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 700;
  src: local("Poppins"),
    url(./fonts/poppins/Poppins-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 800;
  src: local("Poppins"),
    url(./fonts/poppins/Poppins-ExtraBold.ttf) format("truetype");
}

@font-face {
  font-family: "JetBrains Mono";
  src: local("Jetbrains Mono"),
    url(./fonts/jetbrains-mono/JetBrainsMono-Regular.ttf) format("truetype");
}

/* https://github.com/facebook/create-react-app/issues/10373 */

@tailwind base;
@tailwind components;
@tailwind utilities;

/*
 * See https://www.youtube.com/watch?v=MAtaT8BZEAo for theming
 * See https://jeffjadulco.com/blog/dark-mode-react-tailwind/ for dark mode
 */

/* https://tailwindcss.com/docs/customizing-colors#using-css-variables */
@layer base {
  :root {

    --color-blue: 182.0 100.0% 42.0%;
    --color-blue-dark: 213.0 100.0% 48.0%;
    --color-blue-darker: 222.0 91.0% 34.0%;
    --color-green: 158.1 100.0% 43.0%;

    --color-red: 0.0 100.0% 64.0%;
    --color-orange: 47.0 96.0% 55.0%;
    --color-purple: 268.0 67.0% 50.0%;
    --color-pink: 330.0 100.0% 50.0%;
    --color-violet: 268.0 67.0% 50.0%;
    --color-gray: 0.0 0.0% 55.0%;

    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 182.0 100.0% 42.0%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --success: 158.1 100.0% 43.0%;
    --success-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;

    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;

    --border: 216 34% 17%;
    --input: 216 34% 17%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;

    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 216 34% 17%;

    --radius: 0.5rem;
  }
}

/* We need to style the map container to have a height otherwise it does not show */
.leaflet-container {
  height: 300px;
  border-radius: 0.5rem;
}

.loadingAnimationFullScreen {
  animation-duration: 5s;
  animation-timing-function: linear;
  animation-name: slidein;
  animation-iteration-count: infinite;
}

@keyframes slidein {
  from {
    left: 0;
  }

  to {
    left: -200%;
  }
}
